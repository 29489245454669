<template>
  <v-app>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body p-0">
            <!--begin: Wizard-->
            <div
              class="wizard wizard-1"
              id="kt_wizard_v1"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Body-->
              <div
                class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
                v-if="dataLoaded"
              >
                <div class="col-xl-12">
                  <!--begin: Wizard Form-->
                  <form class="form" @submit.stop.prevent="formOnsubmit()">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h3
                        class="
                          font-weight-bolder
                          text-dark
                          font-size-h4 font-size-h1-lg
                        "
                      >
                        Edit Pengumuman
                      </h3>
                      <div class="row">
                        <div class="col-md-4">
                          <b-form-group id="input-group-photo">
                            <label
                              class="col-xl-3 col-lg-5 col-form-label pl-0 pt-0"
                              >Gambar</label
                            >
                            <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                              <div
                                class="image-input image-input-outline"
                                id="kt_profile_avatar"
                              >
                                <div class="image-input-wrapper">
                                  <img :src="photo" alt="" />
                                </div>
                                <label
                                  class="
                                    btn
                                    btn-xs
                                    btn-icon
                                    btn-circle
                                    btn-white
                                    btn-hover-text-primary
                                    btn-shadow
                                  "
                                  data-action="change"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Change avatar"
                                >
                                  <i class="fa fa-pen icon-sm text-muted"></i>
                                  <input
                                    type="file"
                                    name="profile_avatar"
                                    accept=".png, .jpg, .jpeg"
                                    @change="onFileChange($event)"
                                  />
                                  <input
                                    type="hidden"
                                    name="profile_avatar_remove"
                                  />
                                </label>
                                <span
                                  class="
                                    btn
                                    btn-xs
                                    btn-icon
                                    btn-circle
                                    btn-white
                                    btn-hover-text-primary
                                    btn-shadow
                                  "
                                  data-action="cancel"
                                  data-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i
                                    class="ki ki-bold-close icon-xs text-muted"
                                  ></i>
                                </span>
                                <span
                                  class="
                                    btn
                                    btn-xs
                                    btn-icon
                                    btn-circle
                                    btn-white
                                    btn-hover-text-primary
                                    btn-shadow
                                  "
                                  data-action="remove"
                                  data-toggle="tooltip"
                                  title="Remove avatar"
                                  @click="current_photo = null"
                                >
                                  <i
                                    class="ki ki-bold-close icon-xs text-muted"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-md">
                          <!-- Name input -->
                          <b-form-group
                            id="input-group-title"
                            label="Nama:"
                            label-for="input-title"
                          >
                            <b-form-input
                              id="input-title"
                              v-model="form.title"
                              placeholder="Nama Pengumuman"
                            ></b-form-input>
                            <small class="text-danger">{{ error.title }}</small>
                          </b-form-group>

                          <!-- Category Announcement Input-->
                          <b-form-group
                            id="input-group-announcement-category"
                            label="Kategori Pengumuman:"
                            label-for="input-announcement-category"
                          >
                            <treeselect
                              v-model="form.announcement_category_id"
                              :multiple="false"
                              :options="announcement_categories"
                              @select="setAnnouncementCategoryName"
                            />
                            <small class="text-danger">{{
                              error.announcement_category_id
                            }}</small>
                          </b-form-group>

                          <!-- Input Start Date -->
                          <b-form-group
                            id="input-group-start-date"
                            label="Tanggal Mulai ditampilkan:"
                            label-for="input-start-date"
                          >
                            <v-dialog
                              ref="dialog"
                              v-model="modal1"
                              :return-value.sync="form.start_date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <b-form-input
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  id="input-birt-start-date"
                                  v-model="form.start_date"
                                >
                                </b-form-input>
                              </template>

                              <v-date-picker
                                locale="id"
                                v-if="modal1"
                                v-model="form.start_date"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal1 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(form.start_date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>

                            <small class="text-danger">{{
                              error.start_date
                            }}</small>
                          </b-form-group>

                          <!-- Input Start Date -->
                          <b-form-group
                            id="input-group-end-date"
                            label="Tanggal Berakhir ditampilkan:"
                            label-for="input-end-date"
                          >
                            <v-dialog
                              ref="dialog_donation"
                              v-model="modal2"
                              :return-value.sync="form.end_date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <b-form-input
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  id="input-last-donation-end-date"
                                  v-model="form.end_date"
                                >
                                </b-form-input>
                              </template>

                              <v-date-picker
                                locale="id"
                                v-if="modal2"
                                v-model="form.end_date"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal2 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.dialog_donation.save(form.end_date)
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>

                            <small class="text-danger">{{ error.date }}</small>
                          </b-form-group>
                          <b-form-group
                            id="input-group-content"
                            label="Konten:"
                            label-for="input-content"
                          >
                            <b-form-textarea
                              id="input-content"
                              v-model="form.content"
                              rows="3"
                              placeholder="Konten"
                            ></b-form-textarea>
                            <small class="text-danger">{{
                              error.content
                            }}</small>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button type="submit" variant="primary"
                          >Simpan</b-button
                        >
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="default"
                          @click="$router.push('/masters/announcements')"
                        >
                          Batal
                        </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                  <!--end: Wizard Form-->
                </div>
              </div>
              <!--end: Wizard Body-->
            </div>
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        title: "",
        content: "",
        announcement_category_id: "",
        announcement_category_name: "",
        image: "/images/default-announcement.svg",
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
      },
      error: {
        title: "",
        content: "",
        announcement_category_id: "",
        announcement_category_name: "",
        image: "",
        start_date: "",
        end_date: "",
      },
      //   other
      modal1: false,
      modal2: false,
      current_photo: '/img/announcements/default-announcements.svg',
      default_photo: '/img/announcements/default-announcements.svg',
      announcement_categories: [],
      dataLoaded: false
    };
  },
  methods: {
    async get() {
      this.form = await module.get('api/announcements/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/masters/announcements')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
        this.current_photo = this.form.image
      }
    },

    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image = "";
    },

    setAnnouncementCategoryName(evt) {
      //(evt);
      this.form.announcement_category_name = evt.label;
    },

    async getAnnoucmentCategoriesOption() {
      let response = await module.setTreeSelect("api/announcement-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.announcement_categories = response.data;
        this.announcement_categories.unshift({
          label: "Pilih Kategori Pengumuman",
          id: "",
          isDisabled: true,
        });
      }
    },
    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, "api/announcements/" + this.form.id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/announcements");
        this.$root.$emit("refreshAnnouncementActive")
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Pengumuman", route: "/masters/announcements" },
      { title: "Edit Pengumuman" },
    ]);
    this.get()
    this.getAnnoucmentCategoriesOption();
  },

  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>