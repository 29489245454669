import { render, staticRenderFns } from "./AnnouncementUpdate.vue?vue&type=template&id=d631e2b6&scoped=true&"
import script from "./AnnouncementUpdate.vue?vue&type=script&lang=js&"
export * from "./AnnouncementUpdate.vue?vue&type=script&lang=js&"
import style0 from "./AnnouncementUpdate.vue?vue&type=style&index=0&id=d631e2b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d631e2b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VDatePicker,VDialog,VSpacer})
